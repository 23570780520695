<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#3F033B"
        spinner="bar-fade-scale"
      />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
  
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #000">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap>
        <v-flex xs12 py-2 text-left>
          <v-layout wrap>
            <v-flex xs12 px-4 text-left class="mainHeader">BILL DETAILS</v-flex>
          
          </v-layout>
          <v-layout wrap pa-4>
            <v-flex xs4 pr-4>
              <v-card height="100%" class="pa-4 rounded-xl" >
                <v-layout wrap pb-4>
                  <v-flex xs12 v-if="billData.billNo" class="tst1">Bill No</v-flex>
                  <v-flex xs12 v-if="billData.billNo" class="tst2">{{billData.billNo}}</v-flex>
                </v-layout>
                <v-layout wrap pb-4>
                  <v-flex xs12 v-if="billData.create_date" class="tst1">Purchase Date</v-flex>
                  <v-flex xs12 v-if="billData.create_date" class="tst2">{{formatDate(billData.create_date)}}</v-flex>
                </v-layout>
                <v-layout wrap pb-4>
                  <v-flex xs12 v-if="billData.staffCode" class="tst1">Staff Code:</v-flex>
                  <v-flex xs12 v-if="billData.staffCode" class="tst2">{{billData.staffCode}}</v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs4 px-4>
              <v-card height="100%" class="pa-4 rounded-xl">
                <v-layout wrap pb-4>
                  <v-flex xs12 class="tst1">Customer Details</v-flex>
                  <v-flex xs12 class="tst2" v-if="billData.customerName">{{billData.customerName}}</v-flex>
                  <v-flex xs12 class="tst2" v-if="billData.mobileNumber">{{billData.mobileNumber}}</v-flex>
                  <v-flex xs12 class="tst2" v-if="billData.customerId">{{billData.customerId.address}}</v-flex>
                  <v-flex xs12 class="tst2" v-if="billData.customerId">{{billData.customerId.city}}</v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs4 px-4>
              <v-card height="100%" class="pa-4 rounded-xl">
                <v-layout wrap pb-4>
                    <v-flex xs12 v-if="billData.totalNetWeight" ><span class="tst1">Return Amt(₹) : </span><span class="tst2">{{billData.returnAmount}}</span></v-flex>
                    </v-layout>
                    <v-layout wrap pb-4>
                  <v-flex xs12 v-if="ItemTotal" ><span class="tst1">Old Gold Amt(₹) :  </span><span class="tst2">{{billData.oldGoldAmount}}</span></v-flex>
                </v-layout>
                    <v-layout wrap pb-4>
                  <v-flex xs12 v-if="billData.totalNetWeight" ><span class="tst1">Net.Wt(gm) : </span><span class="tst2">{{billData.totalNetWeight}}</span></v-flex>
                </v-layout>
                    <v-layout wrap pb-4>
                  <v-flex xs12 v-if="ItemTotal" ><span class="tst1">Total Amt(₹) :  </span><span class="tst2">{{(ItemTotal).toFixed(2)}}</span></v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 px-4 text-left class="subhead4">ITEMS
            <!-- </v-flex> 
            <v-flex xs12 sm1 px-4 text-left class="subhead4"> -->
              <v-btn  small flat text> 
                <v-icon style="cursor:pointer" title="click to print" @click="downloadReciept()">mdi-printer</v-icon>
            </v-btn>
          </v-flex>
          
          </v-layout>
          <v-layout wrap pa-4 v-if="salesList">
              <v-flex xs12 v-if="salesList.length>0">
                <v-simple-table>
        <thead>
                          <tr>
                            <th class="text-left subhed"><b>S.No.</b></th>
                            <th class="text-left subhed"><b>Code</b></th>
                            <th class="text-left subhed"><b>Count</b></th>
                            <!-- <th class="text-left subhed"><b>Gross.Wt </b></th> -->
                            <th class="text-left subhed"><b>Item/Wt(gm)</b></th>
                            <th class="text-left subhed"><b>Net.Wt(gm)</b></th>
                            <th class="text-left subhed"><b>StonePrice(₹)</b></th>
                            <th class="text-left subhed"><b>Stone.Wt(gm)</b></th>
                            <th class="text-left subhed"><b>Total(₹)</b></th>
                            <th class="text-left subhed"><b>Type</b></th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, i) in salesList"
                            :key="i"
                            class="table"
                          >
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.designId">{{
                                value.designId.code
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.quantity"> {{ value.quantity }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.itemPerWeight">{{
                                value.itemPerWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{ value.netWeight }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stonePrice">{{
                                value.stonePrice
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            
                            <td  v-if="value.taxType==true">
                              <span v-if="value.amountWithGst">{{
                                (value.amountWithGst).toFixed(2)
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td  v-else>
                              <span v-if="value.amountWithoutGst">{{
                                (value.amountWithoutGst).toFixed(2)
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.taxType==true">GST</span>
                              <span v-else>Demo</span>
                            </td>
                          </tr>
                        </tbody>
                    </v-simple-table>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="returnList">
            <v-flex xs12 sm6 px-4 text-left class="subhead4" v-if="returnList.length>0">Return Gold Details
            <!-- </v-flex>
            <v-flex xs12 sm1 px-4 text-left class="subhead4"> -->
              <v-btn  small flat text> 
                <v-icon style="cursor:pointer" title="click to print" @click="downloadReturnReciept()">mdi-printer</v-icon>
            </v-btn>
          </v-flex>
            <v-flex xs12 sm6 px-4 text-right class="subhead4" v-if="returnList.length>0">Bill No: {{ returnBillNo }}</v-flex>
                <v-flex xs12 v-if="returnList.length>0" class="pa-4">
                    <v-card elevation="0" >
                        <v-simple-table>
                      <template v-slot:default>
                        <thead style="text-transform: uppercase">
                          <tr>
                            <!-- <th class="text-left subhed"><b>Action</b></th> -->
                            <!-- <th class="text-left subhed"><b>Sno.</b></th> -->
                            <th class="text-left subhed">
                              <b>Item</b>
                            </th>
                            <th class="text-left subhed"><b>HSN</b></th>
                            <th class="text-left subhed">
                              <b>Qty</b>
                            </th>
                            <th class="text-left subhed"><b>Gr.wt(gm)</b></th>
                            <th class="text-left subhed"><b>St.wt(gm)</b></th>
                            <th class="text-left subhed"><b>Net.wt(gm)</b></th>
                            <th class="text-left subhed"><b>St.pr(₹)</b></th>
                            <th class="text-left subhed"><b>Mk.Chg(₹)</b></th>
                            <th class="text-left subhed"><b>VA(%)</b></th>
                            <th class="text-left subhed"><b>Discount(₹)</b></th>
                            <!-- <th class="text-left subhed"><b>Ad.MK.Chg(₹)</b></th>
                            <th class="text-left subhed"><b>Ad.VA(%)</b></th> -->
                            <th class="text-left subhed"><b>Total(₹)</b></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value3, i) in returnList" :key="i" class="table">
                        
                            <td>
                              <span v-if="value3.designId"
                                >{{ value3.designId.name }}-{{
                                  value3.itemId.name
                                }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value3.designId">
                                {{ value3.designId.HSN.hsnCode }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value3.quantity">{{
                                value3.quantity
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value3.grossWeight">{{
                                value3.grossWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value3.stonWeight">{{
                                value3.stonWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value3.netWeight">{{
                                value3.netWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value3.stonePrice">{{
                                value3.stonePrice
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value3.makingCharge">{{
                                value3.makingCharge.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value3.value3Addition">{{
                                (value3.value3Addition).toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value3.discountAmount">{{
                                (value3.discountAmount).toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value3.itemTotalAmount">{{
                                value3.itemTotalAmount.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                             
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    </v-card>
                </v-flex>
            </v-layout>
              <v-layout wrap v-if="oldgoldList">
            <v-flex xs12 px-4 text-left class="subhead4" v-if="oldgoldList.length>0">Old Gold Details
            <!-- </v-flex>
            <v-flex xs12 sm1 px-4 text-left class="subhead4"> -->
              <v-btn  small flat text> 
                <v-icon style="cursor:pointer" title="click to print" @click="downloadOldGoldReciept()">mdi-printer</v-icon>
            </v-btn>
          </v-flex>
                <v-flex xs12 v-if="oldgoldList.length>0" class="pa-4">
                    <v-card elevation="0" >
                        <v-simple-table>
                          <template v-slot:default>
                            <thead >
                              <tr>
                                <th class="text-left subhed"><b>S.No.</b></th>
                                <th class="text-left subhed">
                                  <b>Item</b>
                                </th>
                                <th class="text-left subhed"><b>Rate(₹)</b></th>
                                <th class="text-left subhed">
                                  <b>Qty</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Gr.wt(gm)</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>St.wt(gm)</b>
                                </th>
                                <th class="text-left subhed">
                                  <b>Net.wt(gm)</b>
                                </th>
                                <th class="text-left subhed"><b>Melt(%)</b></th>
                                <th class="text-left subhed">
                                  <b>Amount(₹)</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value2, i) in oldgoldList"
                                :key="i"
                                class="table"
                              >
                                <td>
                                  <span>
                                    {{ i + 1 }}
                                  </span>
                                </td>
                                <td>
                                  <span v-if="value2.itemName">{{
                                    value2.itemName
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value2.goldRate">
                                    {{ value2.goldRate }}</span
                                  >
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value2.quantity">{{
                                    value2.quantity
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value2.grossWeight">{{
                                    value2.grossWeight
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <td>
                                  <span v-if="value2.stoneWeight">{{
                                    value2.stoneWeight
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <td>
                                  <span v-if="value2.netWeight">{{
                                    value2.netWeight
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <td>
                                  <span v-if="value2.melt">{{
                                    value2.melt
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <td>
                                  <span v-if="value2.itemAmount">{{
                                    value2.itemAmount.toFixed(2)
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                    </v-card>
                </v-flex>
            </v-layout>
            <!-- <v-layout wrap>
            <v-flex xs12 px-4 text-left class="subhead4">Old Gold Details</v-flex>
          </v-layout> -->
          <!-- <v-layout wrap pa-4 v-if="settlementList">
              <v-flex xs12 v-if="settlementList.length>0">
                <v-layout wrap>
                  <v-flex xs1 px-2  class="text-left subhed"
                          style="font-size: 15px; color: black">No.</v-flex>
                  <v-flex xs2 pr-2  class="text-left subhed"
                          style="font-size: 15px; color: black">Date</v-flex>
                          <v-flex xs2 pr-2  class="text-left subhed"
                          style="font-size: 15px; color: black">Thankam Rate(₹)</v-flex>
                          <v-flex xs2 pr-2  class="text-left subhed"
                          style="font-size: 15px; color: black">Purchased Thankam(gm)</v-flex>
                  <v-flex xs2 pr-2  class="text-left subhed"
                          style="font-size: 15px; color: black">Amount Paid(₹)</v-flex>
                          <v-flex xs2 pr-2  class="text-left subhed"
                          style="font-size: 15px; color: black">Balance Thankam(gm)</v-flex>           
                </v-layout>
                </v-flex>
                <v-flex v-else>No purchase payment</v-flex>
                <v-flex xs12 py-2 v-for="(item, j) in settlementList"
                            :key="j">
                 
                <v-layout wrap py-2>
                  <v-flex xs1 px-2> <v-card class="pa-2">{{ j+1 }}</v-card></v-flex>
                  <v-flex xs2 pr-2>
                    <v-card class="pa-2" v-if="item.create_date">{{ formatDate(item.create_date) }}</v-card>
                    <v-card class="pa-2" v-else>-</v-card>
                  </v-flex>
                  <v-flex xs2 pr-2>
                    <v-card class="pa-2" v-if="item.thankamRate">{{ (item.thankamRate).toFixed(2) }}</v-card>
                    <v-card class="pa-2" v-else>-</v-card>
                    </v-flex>
                  <v-flex xs2 pr-2>
                    <v-card class="pa-2" v-if="item.totalThankamWeight">{{ (item.totalThankamWeight).toFixed(2) }}</v-card>
                    <v-card class="pa-2" v-else>-</v-card>
                    </v-flex>
                  <v-flex xs2 pr-2>
                    <v-card class="pa-2" v-if="item.payAmount">{{ (item.payAmount).toFixed(2) }}</v-card>
                    <v-card class="pa-2" v-else>-</v-card>
                 </v-flex>
                 <v-flex xs2 pr-2>
                    <v-card class="pa-2" v-if="item.balanceThankam">{{ (item.balanceThankam).toFixed(2) }}</v-card>
                    <v-card class="pa-2" v-else>0</v-card>
                 </v-flex>
                 
                </v-layout>
            
              </v-flex>
                </v-layout> -->
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showsnackbar: false,
        timeout: 2000,
        msg: null,
        salesList:[],
        returnList:[],
        oldgoldList:[],
        billData:[],
        returnBillNo:"",
    taxType:localStorage.getItem("GSTstatus"),
    ItemTotal:"",
      };
    },
    mounted() {
      this.getSales();
      this.getReturns();
      this.getOldGold();
    },
 
    computed: {
      appUser() {
        return this.$store.state.userData;
      },
    },
    methods: {
      getSales() {
        this.appLoading = true;
        axios({
          method: "get",
          url: "/individual/bill/itemdetails",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            saleId:this.$route.query.id,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.salesList = response.data.data;
              this.billData = response.data.saledata;
              if(this.taxType=='true'){
            this.ItemTotal = response.data.saledata.totalAmountWithGst;
            }
            else{
            this.ItemTotal = response.data.saledata.totalAmountWithoutGst;
            }

            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }
  
            // this.msg = response.data.msg;
  
            // this.snackbar = true;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
      getReturns() {
        this.appLoading = true;
        axios({
          method: "get",
          url: "/individual/bill/returnitemdetails",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            saleId:this.$route.query.id,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.returnList = response.data.returnitems;
              this.returnBillNo = response.data.data.billNo;

            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }
  
            // this.msg = response.data.msg;
  
            // this.snackbar = true;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
      getOldGold() {
        this.appLoading = true;
        axios({
          method: "get",
          url: "/individual/bill/oldgolddetails",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            saleId:this.$route.query.id,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.oldgoldList = response.data.oldgolditems;
            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }
  
            // this.msg = response.data.msg;
  
            // this.snackbar = true;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
      downloadReciept() {
      axios({
        method: "get",
        url: "/download/invoice",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.no,
        },
        responseType: "blob",
      })
        .then((response) => {
          // this.appLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Receipt.pdf");
          document.body.appendChild(link);
          link.click();
          // this.$router.push("/salesReport");
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    downloadOldGoldReciept() {
      axios({
        method: "get",
        url: "/download/purchaseinvoice",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.no,
        },
        responseType: "blob",
      })
        .then((response) => {
          // this.appLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "PurhaseInvoice.pdf");
          document.body.appendChild(link);
          link.click();
          // this.$router.push("/salesReport");
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    downloadReturnReciept() {
      axios({
        method: "get",
        url: "/download/voucher",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.no,
        },
        responseType: "blob",
      })
        .then((response) => {
          // this.appLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Voucher.pdf");
          document.body.appendChild(link);
          link.click();
          // this.$router.push("/salesReport");
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
      formatDate(item) {
        var dt = new Date(item);
        var day = dt.getDate();
        var year = dt.getFullYear();
        // var hours = dt.getHours();
        // var minutes = dt.getMinutes();
        dt = dt.toString();
        // var ampm = hours >= 12 ? "pm" : "am";
        // hours = hours % 12;
        // hours = hours ? hours : 12;
        // minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime =
          day +
          " " +
          dt.slice(4, 7) +
          " " +
          year
          //  +
          // " , " +
          // hours +
          // ":" +
          // minutes +
          // " " +
          // ampm;
  
        return strTime;
      },
    },
  };
  </script>
  